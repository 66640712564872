import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Автомийка Sun Arayc
			</title>
			<meta name={"description"} content={"Блискуча чистота, просто безтурботність - Sun Arayc, освіжаючий відпочинок для вашого автомобіля"} />
			<meta property={"og:title"} content={"Головна | Автомийка Sun Arayc"} />
			<meta property={"og:description"} content={"Блискуча чистота, просто безтурботність - Sun Arayc, освіжаючий відпочинок для вашого автомобіля"} />
			<meta property={"og:image"} content={"https://sunarayc.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://sunarayc.com/img/334234244.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://sunarayc.com/img/334234244.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://sunarayc.com/img/334234244.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://sunarayc.com/img/334234244.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://sunarayc.com/img/334234244.png"} />
			<meta name={"msapplication-TileImage"} content={"https://sunarayc.com/img/334234244.png"} />
		</Helmet>
		<Components.Heaeder quarkly-title="Header" />
		<Section
			padding="40px 0 240px 0"
			background="linear-gradient(0deg,rgba(0, 0, 0, 0.1) 0%,rgba(0, 0, 0, 0.5) 100%),#FFFFFF url(https://sunarayc.com/img/1.jpg) 100% 100% /100% no-repeat scroll padding-box"
			min-height="100vh"
			lg-background="#fff"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Автомийка Sun&nbsp;Arayc
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--lead" lg-text-align="center">
				Пориньте у світ, де догляд за автомобілем поєднується зі спокоєм. На автомийці Sun Arayc ми не просто миємо ваш автомобіль - ми балуємо його дотиком безтурботності. Відчуйте унікальне поєднання ретельного очищення та заспокійливої атмосфери, що перетворює рутинну процедуру миття автомобіля на момент дзен-спокою.
				</Text>
				<Box display="flex" sm-flex-direction="column" sm-text-align="center">
					<Link
						href="/contacts"
						padding="12px 24px 12px 24px"
						color="--light"
						background="--color-primary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
						transition="background-color 0.2s ease-in-out 0s"
						flex-direction="row"
					>
						Контакти
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://sunarayc.com/img/2.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://sunarayc.com/img/3.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Відкрийте для себе чарівність автомийки Sun Arayc
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://sunarayc.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Автомийка Sun Arayc пропонує більше, ніж просто миття - це омолоджуючий відпочинок для вашого автомобіля. Наші м'які, але ефективні методи очищення гарантують, що ваш автомобіль вийде з мийки бездоганним і блискучим, а ви насолодитеся хвилиною спокою в нашій спокійній обстановці.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Чому варто обрати автомийку Sun Arayc?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
				>
					Ніжний дотик, стійкий до бруду: наші м'які поролонові щітки та високоякісні шампуні забезпечують глибоке очищення, не пошкоджуючи лакофарбове покриття вашого автомобіля.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#f5f5ff"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						- Хвилина спокою: Розслабтеся в нашій комфортній зоні очікування, де заспокійлива музика та спокійна атмосфера допоможуть вам ненадовго відволіктися від повсякденної метушні.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#f5f5ff"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						- Екологічні практики: Ми дбаємо про навколишнє середовище так само, як і про ваш автомобіль, використовуючи водозберігаючі технології та миючі засоби, що біологічно розкладаються.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#f5f5ff"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						- Швидке та ефективне обслуговування: Оскільки ми цінуємо ваш час, наша кваліфікована команда забезпечує швидке, але ретельне очищення, щоб ви могли швидко повернутися на дорогу.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});